import React, { Fragment, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { Container, Row } from "reactstrap"

import './gridImages.scss'
import baum from '../images/baum.jpg'

const GridImages = ({
  images,
  isLoading,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const toggleLightbox = (selectedIndex) => {
    setSelectedIndex(selectedIndex)
    setIsLightboxOpen(!isLightboxOpen)
  }

  const views = images?.map((image) => ({ source: image.file.url }))

  return (
    <Fragment>
      {(!isLoading && images?.length) ? (
        <Container>

          <Row>
           <div className="imagesCenter">
           <img className="imageTop" src={baum} alt="baum der reisenden"/>

           </div>
            <div className="gallery">
           

              {images.map(({ author, file, id, fluid }, j) => (
                <div
                  className="image"
                  onClick={() => toggleLightbox(j)}
                  key={file.url}
                >
                  <img
                    // fluid={{ src: file.url }}
                    src={file.url}
                  />
                </div>
              ))}
            </div>
          </Row>
        </Container>
      ) : null}

      <ModalGateway>
        {isLightboxOpen && !isLoading ? (
          <Modal onClose={toggleLightbox}>
            <Carousel
              currentIndex={selectedIndex}
              style={{
                view: (base) => ({
                  ...base,
                  height: '80vh',
                }),
                container : () => ({
                  maxHeight: '80vh'
                })
              }}
              views={views}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
};

export default GridImages