import React from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"

import defaultHero from '../images/ger_ligth_bg.jpg';


import "./hero.scss"

import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"


const Hero = ({
  title,
  description,
  image,
}) => {

  return (
    <div
      className="hero"
      id="hero"
      style={{ backgroundImage: `url(${image || defaultHero})` }}
    >
      <section className="h-100 d-flex align-items-center text-center bg-dark-40">
        <Container>
        {/* <img
                src={companyThumbnail}
                alt="Company Thumbnail"
                className="img-head"
              />   */}
          <main className="hero-content">
          <h1 className="title">{title}</h1>

            {/* <h1 className="title">{title}</h1> */}
            {/* <p className="hero-description">{description}</p> */}
            {/* <section className="hero-buttons">
              <Button href="#services">Mehr dazu</Button>
              <Link to="projects/#project">
                <Button outline>Projekte</Button>
              </Link>
            </section> */}
          </main>
        </Container>
      </section>
    </div>
  )
}

export default Hero
