import React from "react"
import { Container, Row, Form, FormGroup, Label, Input, Col, Button } from "reactstrap"
import { toast } from 'react-toastify'

import "./contactForm.scss"

const ContactForm = () => {
  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        toast('Ihre Anfrage wurde erfolgreich gesendet. Wir melden uns bei Ihnen',{ type: 'success' })
      } else {
        toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
      }
    }
    xhr.send(data)
  }
  
  return (
    <div>
      <Container>
        <Row className="contact-form-block">
          <Col>
            <h2>Kontaktformular</h2>
            <Form
             method="POST"
             onSubmit={submitForm}
             action={process.env.GATSBY_FORMSPREE_CONTACT_ENDPOINT}
            >
              <FormGroup>
                <Label for="lehrperson">Name <span className="required">*</span></Label>
                <Input
                  required
                  type="text"
                  name="name"
                  id="Name"
                  placeholder="Name"
                />
              </FormGroup>

              <FormGroup>
                <Label for="email">E-Mail <span className="required">*</span></Label>
                <Input
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail"
                />
              </FormGroup>

              <FormGroup>
                <Label for="nachricht">Nachricht <span className="required">*</span></Label>
                <Input
                  required
                  type="textarea"
                  name="nachricht"
                  id="nachricht"
                  placeholder="Nachricht"
                />
              </FormGroup>

              <Button>Absenden</Button>
              </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactForm
