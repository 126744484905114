import React from "react"
import { Container, Row, Col } from "reactstrap"

import "./description.scss"

const Description = () => {
  return (
    <Container>
      <Row className="description-block">
        <Col>
          <h3>Psychotherapeut SBAP, <br></br> eidgenössisch anerkannter Psychotherapeut <br></br>
zertifizierter Familientherapeut, Gruppenpsychotherapeut SGGG<br></br></h3>
          

          <p>
          Breit gefächerte Ausbildung und Erfahrung mit den Schwerpunkten Psychoanalyse, 
          psychoanalytische Psychotherapie, Kurzpsychotherapie,
           tiefenpsychologisch fundierte Beratungen, Familientherapie, systemische und lösungsorientierte Methoden 
          <br></br>

          </p>

          <p>Musik- und Gestaltungstherapeut am Sanatorium Bellevue, Kreuzlingen 1972/73<br></br>
Psychotherapeutische Praxis seit 1976 (selbständig und ärztlich delegiert) <br></br>
60%-Stelle bei der Jugendberatung der Stadt Zürich 1989 – 2012. <br></br><br></br>
Das Vorgehen richtet sich nach Anmeldungsgrund und Beratungswunsch <br></br>
    • aufdeckende Psychotherapie mit dem Ziel der Ausheilung andauernder seelischer Beschwerden<br></br>
    • Kurzpsychotherapien<br></br>
    • Paar- und Familientherapien auf psychoanalytischer und systemischer Grundlage<br></br>
    • Beratungen mit zielgerichtetem, lösungsorientiertem Vorgehen.<br></br><br></br>
Spezialgebiete: Probleme in Schule, Beruf und Beziehung, Persönlichkeitsstörungen, Prüfungsangst, Probleme Jugendlicher, Mobbing, Stalking. <br></br><br></br>
Zielgruppen: Erwachsene, Jugendliche, Paare, Familien,<br></br><br></br>
Sprachen: Deutsch, Französisch, Englisch<br></br><br></br>
Praxisadresse:<br></br> Ottenbacherstrasse 15, 8909 Zwillikon (bei Affoltern a.A.) und Stapferstrasse 43, 8006 Zürich<br></br><br></br>
Anmeldung unter 044 350 14 10 / 079 566 15 33</p>

          
        </Col>
      </Row>
    </Container>
  )
}

export default Description
