import React from "react"
import { Container, Row, Col } from "reactstrap"

import "./description.scss"

const DescriptionBilder = () => {
  return (
    <Container>
      <Row className="description-block">
        <Col>
          <h3>Kunstfotografie, Kunstkarten<br></br></h3>
          

          <p>
          Ich fotografiere seit 1963, hatte zahlreiche Ausstellungen in Galerien, Theaterfoyers und Geschäften im In- und Ausland.
Kunstkartenverkauf in renommierten Papeterien, Buchhandlungen und grossen Pflanzengeschäften. <br></br><br></br>
Zahlreiche Bilder zu den Themen<br></br>
- Pflanzen – Skulpturen der Natur<br></br>
- abstrakte Natur<br></br>
- Surreales – Hyperreales<br></br>
- alltäglich-Witziges<br></br>
- Schachtdeckel<br></br><br></br>
Die rechteckigen Bilder sind ab Grösse 40 x 60, die quadratischen ab 50 x 50 cm von 1 bis 20 numeriert und signiert,
 aufgezogen auf 1 mm Alu, mit  Distanzrahmen und Laminat (Hochglanz oder Seidenglanz)
          </p>

         

          
        </Col>
      </Row>
    </Container>
  )
}

export default DescriptionBilder
