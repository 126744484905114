import React from "react"
import { Container, Row, Col } from "reactstrap"

import "./description.scss"

const DescriptionMusik = () => {
  return (
    <Container>
      <Row className="description-block">
        <Col>
          <h3>Piano und Gitarre<br></br></h3>
          <p>
          Zwölf Jahre Klavierunterricht, davon sechs bei Albert Schneeberger am Konservatorium Bern. <br></br>
          Weiterbildung in Jazz-Harmonielehre, Improvisation und Percussion. <br></br><br></br>
Pianist und Arrangeur im Duo Modinha (Gesang und Piano) mit vorwiegend brasilianischen (Samba cançao, Bossa Nova) und kubanischen Liedern <br></br>
Piano solo mit brasilianischen und kubanischen Liedern in privatem Rahmen <br></br><br></br>
Viele Jahre Mitglied in verschiedenen Steelbands, Leiter und Arrangeur in  meiner eigenen Band „A Feel for Steel“ bis 1994.<br></br><br></br>
Lehraufträge an der Universität Zürich über karibische Musikkulturen, Veröffentlichungen zu Musiksoziologie und Musikethnologie in NZZ, WOZ und der Fachzeitschrift CURARE.<br></br><br></br>


<a style={{color:'red', fontSize:'28px'}}>AmarÔn</a>
<br></br>



2012 gründeten Rosa Hess (Klarinette, Saxofon, Flöten, Gesang), Elsbeth Schweizer (Akkordeon, Gesang) und Gerold Lotmar (Gitarre) die Gruppe <a style={{color:'red'}}>AmarÔn</a>; fünf Jahre später kam Ferdi Rauber (Perkussion) dazu. <br/>

Alle vier bringen aus verschiedenen Musikrichtungen reichhaltige Erfahrungen mit und suchen behutsam nach Integration von Liedern aus Ost, Süd und West, aus Roma-, Klezmer- und Balkanmusik, italienischen, kubanischen Liedern und Musettes. Sie kreieren vor allem Eigenes, oft Eigenwilliges – wobei  die Grundstimmung der Lieder bewahrt werden soll. 
<br></br><br></br>

Es ist eine Musik, die verbindet: mal fröhlich-feurig-bewegt, mal innig, sehnsüchtig und melancholisch; aber eben immer  <a style={{color:'red'}}>AmarÔn</a>: eine Formation, die im Laufe der Jahre zu einem unverkennbaren Stil gefunden hat.
    <br/>
Alle drei Instrumentalist*innen haben klassische Musik im Hintergrund und bildeten sich später in verschiedenen Musikrichtungen weiter. Ferdi Rauber konzentriert sich seit Jahren auf Perkussion, Naturtonmusik und Obertongesang. Er war Mitbegründer der Klangwelt Tog­genburg. 
    <br/><br></br>

<a style={{color:'red'}}>AmarÔn</a> spielt vorwiegend an privaten Anlässen, an Vernissagen, in Ateliers, Kleintheatern und Kulturrestaurants. 
          </p>          
        </Col>
      </Row>
    </Container>
  )
}

export default DescriptionMusik
