import React from "react"
import { graphql, Img } from "gatsby"
import { ToastContainer } from "react-toastify"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import baum from '../images/baum.jpg'

// ---------- Themes colors ----------
import "../themes/green.scss"
// import "../themes/orange.scss"
import "./index.scss"

// ---------- Components ----------
import Hero from "../components/hero"
import Tabs from '../components/tabs'
// import Erlebnisnachmittag from '../contentfulModels/erlebnisnachmittag'
// import Ferienlager from '../contentfulModels/ferienlager'
import Anlass from '../contentfulModels/anlass'
import Description from '../components/schuleErlebnisseCommon/description'
import DescriptionMusik from '../components/schuleErlebnisseCommon/descriptionMusik'
import DescriptionBilder from '../components/schuleErlebnisseCommon/descriptionBilder'

import ContactForm from '../components/schuleErlebnisseCommon/contactForm'
import Top from '../components/top'
import GridImages from '../components/gridImages'


const IndexPage = ({ data: {
  allContentfulBilder,
  // allContentfulAnlasseTrotte,
  site,
} }) => {
  const { fullTitle, description } = site.siteMetadata

  return (
    <div>
      <ToastContainer />
      <Hero title={fullTitle} description={description} />
      <Top />
      <Tabs
        content={[
          <>
           {/* <img className='imagesCenter' src={baum} alt="baum der reisenden"/> */}
           <Description />
           
        </>,
        <>
        <DescriptionMusik />
        </>,
         <>
         <DescriptionBilder />
         
          {allContentfulBilder.edges?.[0]?.node?.bilder && (
             <GridImages images={allContentfulBilder.edges[0].node.bilder} />
              )}
              {/* {allContentfulAnlasseTrotte?.edges?.map((item) => <Anlass AnlasseTrotte={item?.node} />)} */}
          </>,
          
         
          <ContactForm/>,
        ]}
      />
    </div>
  )
}

export const projectsQuery = graphql`
  query FeaturedProjectsQuery {
    # allContentfulErlebnisEinzelneDaten {
    #   edges {
    #     node {
    #       id
    #       name
    #       preis
    #       spaceId
    #       anfangZeit
    #       endZeit
    #       anzahlPltze
    #       bild {
    #         title
    #         file {
    #           url
    #           fileName
    #         }
    #         description
    #       }
    #       beschreibung {
    #         raw
    #       }
    #       zielgruppe
    #       treffpunkt
    #       kursleitung {
    #         name
    #         beschreibung {
    #           raw
    #         }
    #       }
    #       datum1
    #       datum2
    #       datum3
    #       datum4
    #       datum5
    #       datum6
    #       datum7
    #     }
    #   }
    # }

    # allContentfulAnlasseTrotte (sort: {fields: datum})  {
    #   edges {
    #     node {
    #       titel
    #       beschreibung {
    #         raw
    #       }
    #       anlassBild {
    #         file {
    #           url
    #         }
    #       }
    #       datum
    #     }
    #   }
    # }

    site {
      siteMetadata {
        fullTitle
        description
        about
      }
    }

    allContentfulBilder {
      edges {
        node {
          id
          bilder {
            id
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
